






































































































/* eslint-disable @typescript-eslint/no-explicit-any */
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import { subHours, compareAsc } from 'date-fns'

  import { defineComponent } from '@vue/composition-api'
  import TopBar from '@/components/organisms/o-top-bar.vue'
  import { Company } from '@/api/interfaces/company'
  import { QueryParams } from '@/store/api-plateform-utils'
  import { TraineeImport } from '@/api/interfaces/traineeimport'
  import { mapFields } from 'vuex-map-fields'
  import { TraineeImportType } from '@/api/interfaces/traineeimporttype'
  import { userConnectedHasRole } from '@/composables/UserGrant'
  import { TraineeImportTypeEnum } from '@/api/enums/traineeImportType'
  import store from '@/store'
  import ListImport from '@/views/dashboard/import/components/ListImport.vue'

  export default defineComponent({
    setup () {
      const canImportTrainee = () => userConnectedHasRole('ROLE_WRITE_TRAINEE_IMPORT')
      return {
        canImportTrainee,
      }
    },
    name: 'TraineeImport',
    components: {
      ListImport,
      TopBar,
      SelectCompanies: () => import('@/components/molecules/select-search/company.vue'),
    },
    data () {
      return {
        title: this.$t('menu.import_need_training'),
        dialogImport: false,
        file: null,
        selectedImportType: null,
        company: {
          businessIntroducer: false,
        } as Company,
        refreshTimeoutId: 0,
        isLoadingLastImports: false,
      }
    },
    computed: {
      ...mapState('import', {
        listTraineeImport: 'list',
      }),
      importType (): TraineeImportType|null {
        const traineeImportTypes = (this.company as Company)?.traineeImportTypes as Array<TraineeImportType>

        if (traineeImportTypes?.length === 1) {
          return traineeImportTypes[0]
        }

        if (traineeImportTypes?.length > 1) {
          return this.selectedImportType
        }

        return null
      },
      haveToSelectFileType (): boolean {
        const traineeImportTypes = (this.company as Company)?.traineeImportTypes as Array<TraineeImportType>
        return traineeImportTypes?.length > 1
      },
      exampleFile (): string {
        return (this.importType as TraineeImportType|null)?.sampleFilePath as string || ''
      },
      listImportRequest (): QueryParams {
        const request = {
          itemsPerPage: 10,
          'traineeImportType.name': [
            TraineeImportTypeEnum.INITIAL,
            TraineeImportTypeEnum.DEFAULT,
            TraineeImportTypeEnum.TOYOTA_DELIVERY,
            TraineeImportTypeEnum.TOYOTA_ORDER,
          ],
        }
        return (request as unknown) as QueryParams
      },
    },
    async beforeMount () {
      this.isLoadingLastImports = true
      await this.loadLastImportsAndRefresh()
      this.isLoadingLastImports = false
      await this.loadCompanies()
    },
    destroyed () {
      window.clearTimeout(this.refreshTimeoutId)
    },
    methods: {
      ...mapActions('company', {
        loadCompanies: 'load',
      }),
      ...mapActions('user', {
        sendErrorMessage: 'sendErrorMessage',
      }),
      ...mapActions('import', {
        upload: 'upload',
        loadLastImports: 'loadList',
      }),
      ...mapMutations('import', {
        setCurrentImport: 'setCurrentImport',
      }),
      async importFile () {
        if (this.importType !== null) {
          const formData = new FormData()
          formData.append('importFile', this.file as unknown as string)
          formData.append('company', this.company.id as unknown as string)
          formData.append('traineeImportType', (this.importType as TraineeImportType).id as unknown as string)
          try {
            await this.upload(formData)
            await this.loadLastImportsAndRefresh()
            this.dialogImport = true
          } catch (e: any) {
            this.sendErrorMessage(e.response ? e.response.data.error : e.message)
          }
        }
      },
      async loadLastImportsAndRefresh () {
        try {
          const list: Array<TraineeImport> = await this.loadLastImports(
            this.listImportRequest,
          )
          const notFinishedImport: TraineeImport | undefined = list.find(
            (traineeImport: TraineeImport) => this.isNotFinished(traineeImport),
          )
          const timeLimit = subHours(new Date(), 12)

          if (notFinishedImport && compareAsc(new Date(notFinishedImport.createdAt as Date), timeLimit) > 0) {
            window.clearTimeout(this.refreshTimeoutId)
            this.refreshTimeoutId = window.setTimeout(async () => {
              await this.loadLastImportsAndRefresh()
            }, 1000)
          }
        } catch (e: any) {
          this.sendErrorMessage(e.response ? e.response.data.error : e.message)
        }
      },
      isNotFinished (traineeImport:TraineeImport): boolean {
        return traineeImport.status !== 'FINISH' && traineeImport.status !== 'WARNING'
      },
      disableLinkToTrainees (traineeImport:TraineeImport): boolean {
        return this.isNotFinished(traineeImport) || traineeImport.currentTrainingImported === 0
      },
    },
    beforeRouteEnter (to, from, next) {
      store.commit('import/setList', [])
      next()
    },
  })
